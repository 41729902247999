<div class="nav-container">
  <nav class="navbar navbar-expand-md navbar-dark navbar-custom">
    <div class="container-fluid">
      <div class="navbar-brand mr-5">
        <!-- atomfire logo -->
        <a routerLink="/"><img class="navbar-logo" style="width:180px;" src="assets\img\atomfire-logo3.png"></a>
        <!-- atomfire logo -->
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        [attr.aria-expanded]="!isCollapsed"
        aria-label="Toggle navigation"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="navbar-toggler-icon" style="color: #001B52;"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed" id="navbarNavDropdown">
        <ul class="navbar-nav mr-auto text-uppercase text-center mx-5" >
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" *ngIf="auth.isAuthenticated$ | async" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              creative<b>hub</b>
            </a>
            <div class="dropdown-menu shadow-none rounded-0" aria-labelledby="navbarDropdownMenuLink">
              <a routerLink="steelnebula" routerLinkActive="active" class="dropdown-item">Steel Nebula</a>
              <a routerLink="creativechecker" routerLinkActive="active" class="dropdown-item">creative checker</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" *ngIf="auth.isAuthenticated$ | async" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              pixel<b>hub</b>
            </a>
            <div class="dropdown-menu shadow-none rounded-0" aria-labelledby="navbarDropdownMenuLink">
              <a routerLink="createfloodlights" routerLinkActive="active" class="dropdown-item" href="#">create floodlights</a>
              <a routerLink="eventtags" routerLinkActive="active" class="dropdown-item" href="#">event tags</a>
              <a routerLink="floodlightslist" routerLinkActive="active" class="dropdown-item" href="#">floodlights list</a>
              <a routerLink="gtmmonitor" routerLinkActive="active" class="dropdown-item" href="#">gtm monitor</a>
              <a routerLink="sonicmoon" routerLinkActive="active" class="dropdown-item" href="#">sonicmoon</a>
              <a routerLink="tagstatus" routerLinkActive="active" class="dropdown-item" href="#">tag status</a>
              <a routerLink="voicelabs" routerLinkActive="active" class="dropdown-item" href="#">voicelabs</a>           
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" *ngIf="auth.isAuthenticated$ | async" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              campaign<b>hub</b>
            </a>
            <div class="dropdown-menu shadow-none rounded-0" aria-labelledby="navbarDropdownMenuLink">
              <a routerLink="external-api" routerLinkActive="active" class="dropdown-item" href="#">external api</a>
              <a routerLink="qatool" routerLinkActive="active" class="dropdown-item" href="#">qa tool</a>
              <a routerLink="delivery-check" routerLinkActive="active" class="dropdown-item" href="#">delivery check</a>
            </div>
          </li>
        </ul>
        <!-- <ul class="navbar-nav navbar-dark navbar-custom text-uppercase text-center">
          <li class="nav-item dropdown" *ngIf="auth.isAuthenticated$ | async">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownAdvertisers" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Adserver
            </a>
            <div class="dropdown-menu pre-scrollable" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLinkActive="active" href="#">DCM 6602</a>
             </div>
          </li>
        </ul> -->

        <ul class="navbar-nav d-none d-md-block">
          <!-- Login button: show if NOT authenticated -->
          <li
            class="nav-item"
            *ngIf="(auth.isAuthenticated$ | async) === false"
          >
            <button
              id="qsLoginBtn"
              class="btn btn-primary btn-margin"
              (click)="loginWithRedirect()"
            >
              Log in
            </button>
          </li>
          <!-- / Login button -->

          <!-- Fullsize dropdown: show if authenticated -->
          <li
            class="nav-item dropdown"
            *ngIf="auth.user$ | async as user"
            ngbDropdown
          >
            <a
              ngbDropdownToggle
              class="nav-link dropdown-toggle"
              id="profileDropDown"
              data-toggle="dropdown"
            >
            <!-- Profile image should be set to the profile picture from the id token -->
              <img
                [src]="user.picture"
                alt="Profile picture"
                class="nav-user-profile rounded-circle"
                style="width: 75px;"
              />
              <div class="d-inline-flex p-2 float-right">{{ user.name }}<br>{{ user.email }}</div>
            </a>
            <div class="dropdown-menu dropdown-menu-left" ngbDropdownMenu>
              <!-- Show the user's full name from the id token here -->
              <div class="dropdown-header">
                {{ user.name }}
              </div>
              <a routerLink="/profile" class="dropdown-item dropdown-profile">
                <fa-icon [icon]="faUser" class="mr-3"></fa-icon> Profile
              </a>
              <button
                (click)="logout()"
                class="btn btn-link dropdown-item"
                id="qsLogoutBtn"
              >
                <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon> Log out
              </button>
            </div>
          </li>
          <!-- /Fullsize dropdown -->
        </ul>

        <!-- Responsive login button: show if NOT authenticated -->
        <ul
          class="navbar-nav d-md-none"
          *ngIf="(auth.isAuthenticated$ | async) === false"
        >
          <button
            class="btn btn-primary btn-block"
            id="qsLoginBtn"
            (click)="loginWithRedirect()"
          >
            Log in
          </button>
        </ul>
        <!-- /Responsive login button -->

        <!-- Responsive profile dropdown: show if authenticated -->
        <ul
          class="navbar-nav d-md-none justify-content-between"
          *ngIf="auth.user$ | async as user"
          style="min-height: 170px;"
        >
          <li class="nav-item">
            <span class="user-info">
              <!-- Profile image should be set to the profile picture from the id token -->
              <img
                alt="Profile picture"
                class="nav-user-profile d-inline-block rounded-circle mr-3"
                style="width: 75px;"
                [src]="user.picture"
              />
              <!-- Show the user's full name from the id token here -->
              <h6 class="d-inline-block" style="color: #001B52;">{{ user.name }}</h6>
            </span>
          </li>
          <li>
            <fa-icon [icon]="faUser" class="mr-3"></fa-icon>
            <a routerLink="/profile">Profile</a>
          </li>

          <li>
            <fa-icon [icon]="faPowerOff" class="mr-3"></fa-icon>
            <button
              class="btn btn-link p-0"
              id="qsLogoutBtn"
              (click)="logout()"
            >
              Log out
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
