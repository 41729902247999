<app-alert [alert]="alert"></app-alert>
<div class="input-group my-3 p-2 custom">
    <textarea class="form-control p-3 rounded-0 border-0"
        placeholder="Please input search terms one per line or comma separated"
        aria-label="Text area for submitting questions" [(ngModel)]="questions"></textarea>
</div>

<div class="row">
    <div class="last-run col-3">
        <ng-container *ngIf="statusData && statusData['questioner']">
            Posting questions... {{ statusData['questioner']['total_asked'] }}/{{
            statusData['questioner']['total_questions'] }} questions
        </ng-container>
        <label *ngIf="statusData && statusData['questioner']">Questioner status last run: {{
            statusData['questioner']['last_run_timestamp'] | date:'MMM d, y, h:mm:ss a' }}</label>
    </div>

    <div class="last-run col-3">
        <ng-container *ngIf="statusData && statusData['converter']">
            {{ statusData['converter']['total_converted'] }}/{{ statusData['converter']['total_to_convert'] }} converted
        </ng-container>
        <label *ngIf="statusData && statusData['converter']">Converter status last run: {{
            statusData['converter']['last_run_timestamp'] | date:'MMM d, y, h:mm:ss a' }}</label>
    </div>

    <div class="col-6">
        <button class="btn runcrawler-button rounded-0 mx-1 px-4 py-0 float-right" type="button" (click)="copyData()"
            data-toggle="tooltip" data-placement="top" title="Copy table to clipboard">Copy Table</button>
        <button class="btn runcrawler-button rounded-0 mx-1 px-4 py-0 float-right" type="button" (click)="convertWav()"
            data-toggle="tooltip" data-placement="top" title="Convert answers into text after posting them">Convert
            Answers</button>
        <button class="btn runcrawler-button rounded-0 mx-1 px-4 py-0 float-right" type="button"
            (click)="submitQuestions()" data-toggle="tooltip" data-placement="top"
            title="Asking questions to Google Assistant">Post Questions</button>
    </div>
</div>

<table class="mt-3 col-12 text-center table-bordered border-light">
    <thead>
        <tr class="heading text-center text-white">
            <th *ngFor="let column of columns" scope="col">{{column.name}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let row of rows">
            <td *ngFor="let cel of row | keyvalue: noSorting">
                <ng-container [ngSwitch]="cel.key">
                    <ng-container *ngSwitchCase="'date'">{{cel.value | date}}</ng-container>
                    <ng-container *ngSwitchCase="'wav'">
                        <audio controls>
                            <source [src]="cel.value" type="audio/wav">
                            Your browser does not support the audio element.
                        </audio>
                    </ng-container>
                    <ng-container *ngSwitchCase="'html'">
                        <a [href]="cel.value" target="_blank">
                            link
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchDefault>{{cel.value}}</ng-container>
                </ng-container>
            </td>
        </tr>
    </tbody>
</table>


<nav class="pt-2">
    <ul class="pagination pagination-sm justify-content-end">
        <li class="page-item">
            <button class="page-link" (click)="getResults(50)">50</button>
        </li>
        <li class="page-item">
            <button class="page-link" (click)="getResults(100)">100</button>
        </li>
        <li class="page-item">
            <button class="page-link" (click)="getResults(200)">200</button>
        </li>
        <li class="page-item">
            <button class="page-link" (click)="getResults(400)">400</button>
        </li>
    </ul>
</nav>