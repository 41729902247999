import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tagstatus',
  templateUrl: './tagstatus.component.html',
  styleUrls: ['./tagstatus.component.css']
})
export class TagstatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
