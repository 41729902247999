import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-floodlightslist',
  templateUrl: './floodlightslist.component.html',
  styleUrls: ['./floodlightslist.component.css']
})
export class FloodlightslistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
