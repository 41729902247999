<div *ngIf="alert && alert['msg_type'] == 'info'" class="alert rounded-0 mt-1 p-0 font-weight-bold text-center bg-info" role="alert">
    {{alert['status_msg']}}
</div>

<div *ngIf="alert && alert['msg_type'] == 'warning'" class="alert rounded-0 mt-1 p-0 font-weight-bold text-center bg-warning" role="alert">
    {{alert['status_msg']}}
</div>

<div *ngIf="alert && alert['msg_type'] == 'error'" class="alert rounded-0 mt-1 p-0 font-weight-bold text-center bg-danger" role="alert">
    {{alert['status_msg']}}
</div>