<app-alert [alert]="alert"></app-alert>

<!-- Popup with list of jobs -->
<div class="modal fade" id="jobsModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="running-tab" data-toggle="tab" href="#running" role="tab" aria-controls="running" aria-selected="true">Running</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="finished-tab" data-toggle="tab" href="#finished" role="tab" aria-controls="finished" aria-selected="false">Finished</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="pending-tab" data-toggle="tab" href="#pending" role="tab" aria-controls="pending" aria-selected="false">Pending</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="running" role="tabpanel" aria-labelledby="running-tab">
            <ul class="list-group" *ngIf="jobs">
              <li class="list-group-item border-0" *ngFor="let job of jobs['running']">
                {{job.id}} | {{job.start_time}}
                <button type="button" class="btn badge badge-danger" (click)="cancelJob(job.id)">Cancel</button>
              </li>
            </ul>
          </div>
          <div class="tab-pane fade" id="finished" role="tabpanel" aria-labelledby="finished-tab">
            <ul class="list-group" *ngIf="jobs">
              <li class="list-group-item border-0" *ngFor="let job of jobs['finished']">{{job.id}} | {{job.start_time}} | {{job.end_time}}</li>
            </ul>
          </div>
          <div class="tab-pane fade" id="pending" role="tabpanel" aria-labelledby="pending-tab">
            <ul class="list-group" *ngIf="jobs">
              <li class="list-group-item border-0" *ngFor="let job of jobs['pending']">{{job.id}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Input field with buttons -->
<div class="input-group my-3 p-2 custom">
    <input type="text" class="form-control p-3 rounded-0" placeholder="" aria-label="Example text with button addon" [(ngModel)]="url">
    <div class="input-group-append font-large ml-2">
        <button class="btn runcrawler-button rounded-0 mr-2" (click)="scheduleJob(url)" type="button">Run Crawler</button>
        <button class="btn runcrawler-button rounded-0" data-toggle="modal" data-target="#jobsModal" (click)="listJobs()" type="button">Check Status</button>
    </div>
</div>

<div class="embed-responsive embed-responsive-1by1">
  <iframe class="embed-responsive-item" src="https://datastudio.google.com/embed/reporting/5d8fb937-7b9f-4298-8b41-d67198e0fb50/page/SRULB"></iframe>
</div>

<!-- <div class="btn-group col-6 pr-2 pl-0">
    <button type="button" class="btn dropdown-toggle custom rounded-0 text-white font-large" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
      Site URL Search
    </button>
    <div class="dropdown-menu dropdown-menu-lg-right font-large custom text-center rounded-0 text-white col-11 mr-2 width">
      <button class="dropdown-item text-white" type="button">Action</button>
      <button class="dropdown-item text-white" type="button">Another action</button>
      <button class="dropdown-item text-white" type="button">Something else here</button>
    </div>
</div>

  <div class="btn-group col-6 pl-2 pr-0">
    <button type="button" class="btn dropdown-toggle custom rounded-0 text-white font-large" data-toggle="dropdown" data-display="static" aria-haspopup="true" aria-expanded="false">
      Pixel Vendor Search
    </button>
    <div class="dropdown-menu dropdown-menu-lg-left font-large custom text-center rounded-0 text-white col-11 ml-2 width">
      <button class="dropdown-item text-white" type="button">Action</button>
      <button class="dropdown-item text-white" type="button">Another action</button>
      <button class="dropdown-item text-white" type="button">Something else here</button>
    </div>
  </div> -->

  <!-- <table class="mt-3 col-12 text-center">
    <tr class="heading text-center text-white">
    <td>Site URL</td>
    <td>Pixel on Page</td>
    </tr>
    <tr>
        <td>dreams.co.uk</td>
        <td>facebook.com</td>
        </tr>
  </table> -->

<!-- <div class="input-group my-3 p-2 custom">
<input type="text" class="form-control" placeholder="" aria-label="Example text with button addon" [(ngModel)]="jobID">
    <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="cancelDomain(jobID)" type="button">Cancel</button>
    </div>
</div> -->

<!-- <ul *ngFor="job in joblist">
<li>{{job.id}}</li>
</ul> -->