import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';


@Component({
  selector: 'app-voicelabs',
  templateUrl: './voicelabs.component.html',
  styleUrls: ['./voicelabs.component.css']
})
export class VoicelabsComponent implements OnInit {

  questions: String;
  alert: Object;
  questioner_cur_percent: number;
  converter_cur_percent: number;
  statusData: Object;
  columns: Object;
  rows: Object;

  constructor(private data: ApiService) { }

  ngOnInit(): void {
    this.checkStatus();
    this.getResults(50);
  }

  getResults(limit) {
    this.data.checkResultsVoicelabs(limit).subscribe(
      data => {
        this.columns = data.schema.fields;
        this.rows = data.data;
      }
    )
  }

  submitQuestions() {
    let final_array = [];

    let array_of_questions = this.questions.replace(/(?:\r\n|\r|\n)/g, ',').split(',');
    for (let question in array_of_questions) {
      let question_str = array_of_questions[question].trim()
      final_array.push(question_str);
    }

    this.data.submitQuestionsVoicelabs(final_array).subscribe(
      data => {
        this.alert = data;
      }
    )
  }

  checkStatus() {
    this.data.checkStatusVoicelabs().subscribe(
      data => {
        this.questioner_cur_percent = data['questioner']['total_asked'] / data['questioner']['total_questions'] * 100;
        this.converter_cur_percent = data['converter']['total_converted'] / data['converter']['total_to_convert'] * 100;
        this.statusData = data;
      }
    )
  }

  convertWav() {
    this.data.convertWavVoicelabs().subscribe(
      data => {
        this.alert = data;
      }
    )
  }

  noSorting() {
    return 0;
  }

  copyData() {
    let clipboardData = "";
    for (let row in this.rows) {
      let cells = this.rows[row];
      for (let cell in cells) {
        clipboardData += cells[cell] + "\t";
      }
      clipboardData += "\n";
    }
    navigator.clipboard.writeText(clipboardData).then().catch(e => console.error(e));
  }

}
