import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-delivery-check',
  templateUrl: './delivery-check.component.html',
  styleUrls: ['./delivery-check.component.css']
})
export class DeliveryCheckComponent implements OnInit {

  columns: Array<Object>;
  rows: Array<Object>;
  filteredRows: Array<Object>;
  advertiser: String;
  
  constructor(private data: ApiService) { }

  ngOnInit(): void {
    this.data.deliveryCheckTable().subscribe(
      data => {
        this.columns = data.schema.fields;
        this.rows = data.data;
        this.filteredRows = data.data;
      }
    )
  } 

  filterRows() {
    this.filteredRows = this.rows.filter(
      (row) => (row['advertiser'] == this.advertiser) 
    )
  }

  clearfilterRows() {
    this.filteredRows = this.rows;
  }

}
