import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-creativechecker',
  templateUrl: './creativechecker.component.html',
  styleUrls: ['./creativechecker.component.css']
})
export class CreativecheckerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
