import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import json from 'highlight.js/lib/languages/json';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ExternalApiComponent } from './pages/external-api/external-api.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { VoicelabsComponent } from './pages/voicelabs/voicelabs.component';
import { AlertComponent } from './components/alert/alert.component';
import { SonicmoonComponent } from './pages/sonicmoon/sonicmoon.component';
import { SteelnebulaComponent } from './pages/steelnebula/steelnebula.component';
import { FloodlightslistComponent } from './pages/floodlightslist/floodlightslist.component';
import { QatoolComponent } from './pages/qatool/qatool.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { FinderComponent } from './pages/finder/finder.component';
import { TagstatusComponent } from './pages/tagstatus/tagstatus.component';
import { EventtagsComponent } from './pages/eventtags/eventtags.component';
import { GtmmonitorComponent } from './pages/gtmmonitor/gtmmonitor.component';
import { DropdetectorComponent } from './pages/dropdetector/dropdetector.component';
import { CreatefloodlightsComponent } from './pages/createfloodlights/createfloodlights.component';
import { CreativecheckerComponent } from './pages/creativechecker/creativechecker.component';
import { DeliveryCheckComponent } from './pages/delivery-check/delivery-check.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    NavBarComponent,
    FooterComponent,
    HomeContentComponent,
    LoadingComponent,
    ExternalApiComponent,
    VoicelabsComponent,
    AlertComponent,
    SonicmoonComponent,
    SteelnebulaComponent,
    FloodlightslistComponent,
    QatoolComponent,
    ChangelogComponent,
    FinderComponent,
    TagstatusComponent,
    EventtagsComponent,
    GtmmonitorComponent,
    DropdetectorComponent,
    CreatefloodlightsComponent,
    CreativecheckerComponent,
    DeliveryCheckComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    HighlightModule,
    FontAwesomeModule,
    FormsModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/highlight'),
        languages: {
          json: () => import('highlight.js/lib/languages/json'),
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
