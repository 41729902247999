import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ExternalApiComponent } from './pages/external-api/external-api.component';
import { VoicelabsComponent } from './pages/voicelabs/voicelabs.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { SonicmoonComponent } from './pages/sonicmoon/sonicmoon.component';
import { SteelnebulaComponent } from './pages/steelnebula/steelnebula.component';
import { FloodlightslistComponent } from './pages/floodlightslist/floodlightslist.component';
import { QatoolComponent } from './pages/qatool/qatool.component';
import { FinderComponent } from './pages/finder/finder.component';
import { TagstatusComponent } from './pages/tagstatus/tagstatus.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { CreatefloodlightsComponent } from './pages/createfloodlights/createfloodlights.component';
import { CreativecheckerComponent } from './pages/creativechecker/creativechecker.component';
import { DropdetectorComponent } from './pages/dropdetector/dropdetector.component';
import { EventtagsComponent } from './pages/eventtags/eventtags.component';
import { GtmmonitorComponent } from './pages/gtmmonitor/gtmmonitor.component';
import { DeliveryCheckComponent } from './pages/delivery-check/delivery-check.component';

const routes: Routes = [
  {
    path: 'steelnebula',
    component: SteelnebulaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'external-api',
    component: ExternalApiComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'voicelabs',
    component: VoicelabsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sonicmoon',
    component: SonicmoonComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'floodlightslist',
    component: FloodlightslistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'qatool',
    component: QatoolComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'finder',
    component: FinderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'changelog',
    component: ChangelogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tagstatus',
    component: TagstatusComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'createfloodlights',
    component: CreatefloodlightsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'creativechecker',
    component: CreativecheckerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dropdetector',
    component: DropdetectorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'eventtags',
    component: EventtagsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'gtmmonitor',
    component: GtmmonitorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'delivery-check',
    component: DeliveryCheckComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
