import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-sonicmoon',
  templateUrl: './sonicmoon.component.html',
  styleUrls: ['./sonicmoon.component.css']
})
export class SonicmoonComponent implements OnInit {

  alert: Object;
  url: String;
  jobs: Object;

  constructor(private data: ApiService) { }

  ngOnInit(): void {
  }

  scheduleJob(url) {
    // URL shoud start with http or https
    let regexp = /^(http|https):\/\/([A-Za-z0-9-\.]+?\.[A-Za-z]+)$/;
    if (regexp.test(url)) {
      let domain = url.match(regexp)[2];
      this.data.scheduleJobSonicmoon(url, domain).subscribe(
        data => {
          this.alert = data;
        }
      )

    }
    else {
      this.alert = { 'msg_type': 'error', 'status_msg': 'Invalid URL, format should be http://example.com' };
    }
  }

  cancelJob(jobID) {
    this.data.cancelJobSonicmoon(jobID).subscribe(
      data => {
        this.alert = data;
      }
    )
  }

  listJobs() {
    this.data.listJobsSonicmoon().subscribe(
      data => {
        this.jobs = data;
      }
    )
  }

}
