<div class="input-group my-3 p-2 custom">
  <input type="text" class="form-control p-3 rounded-0" placeholder="" aria-label="Example text with button addon" [(ngModel)]="advertiser">
  <div class="input-group-append font-large ml-2">
      <button class="btn runcrawler-button rounded-0 mr-2" type="button" (click)="filterRows()">Search Advertiser</button>
      <button class="btn runcrawler-button rounded-0 mr-2" type="button" (click)="clearfilterRows()">Clear</button>
  </div>
</div>



<table class="table table-responsive">
  <thead>
    <tr>
      <ng-container *ngFor="let column of columns">
        <th *ngIf="column.name != 'index'"  class="custom" scope="col">{{ column.name }}</th>
      </ng-container>
    </tr>
  </thead>
  <tbody >
    <tr *ngFor="let row of filteredRows" scope="row">
      <td>{{ row.advertiser }}</td>
      <td>{{ row.advertiser_id }}</td>
      <td>{{ row.placement }}</td>
      <td>{{ row.placement_id }}</td>
      <td>{{ row.placement_start_date }}</td>
      <td>{{ row.impressions }}</td>
      <td>{{ row.clicks }}</td>
    </tr>
  </tbody>
</table>