import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as config from '../../auth_config.json';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) { }

  ping$(): Observable<any> {
    console.log(config.apiUri);
    return this.http.get(`${config.apiUri}/arche`);
  }

  deliveryCheckTable(): Observable<any> {
    return this.http.get(`${config.apiUri}/delivery_check`);
  }

  convertWavVoicelabs(): Observable<any> {
    return this.http.get(`${config.apiUri}/voicelabs/convert`);
  }

  checkStatusVoicelabs(): Observable<any> {
    return this.http.get(`${config.apiUri}/voicelabs/status`);
  }

  submitQuestionsVoicelabs(questions): Observable<any> {
    return this.http.post(`${config.apiUri}/voicelabs/submit`, questions);
  }

  checkResultsVoicelabs(limit: number): Observable<any> {
    return this.http.get(`${config.apiUri}/voicelabs/results?limit=${limit}`);
  }

  listJobsSonicmoon(): Observable<any> {
    return this.http.get(`${config.apiUri}/sonicmoon/jobs`);
  }

  scheduleJobSonicmoon(urls, domains): Observable<any> {
    return this.http.get(`${config.apiUri}/sonicmoon/schedule?urls=${urls}&domains=${domains}`);
  }

  cancelJobSonicmoon(job): Observable<any> {
    return this.http.get(`${config.apiUri}/sonicmoon/cancel?job=${job}`);
  }


}
